<template>
  <div class="app-container">
    <el-dialog title="Add BSC" :visible.sync="addDialog" width="60%" :show-close="false" :close-on-click-modal="false">
      <el-form :model="addBSCCompanyForm" label-width="115px" :rules="rulesBSCCompany" ref="addBSCCompanyForm" label-position="top" size="mini" :validate-on-rule-change="false">
        <el-row :gutter="24" style="padding: 0px 30px 0px 30px;">
          <el-col :md="8" :lg="8" :xs="24" :sm="24" >
            <el-form-item label="Company:" prop="selectedCompany">
              <el-select style="width: 100%;" v-model="addBSCCompanyForm.selectedCompany" filterable>
                <el-option v-for="company in companies" :key="company.id" :value="company.id" :label="company.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="8" :xs="24" :sm="24" >
            <el-form-item label="Name:" prop="bscName">
              <el-input autocomplete="off" v-model="addBSCCompanyForm.bscName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="8" :xs="24" :sm="24" >
            <el-form-item label="Prevous BSC:" prop="selectedPreviousBSC">
              <el-select style="width: 100%;" v-model="addBSCCompanyForm.selectedPreviousBSC" filterable>
                <el-option v-for="company in companies" :key="company.id" :value="company.id" :label="company.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddEval">Cancel</el-button>
        <el-button style="background:#EB2C3E; color:white;" @click="submitAddBSCCompanyForm">Confirm</el-button>
      </span>
    </el-dialog>
    <el-card>
      <el-dialog title="Add Section" :visible.sync="addSectionDialog" width="40%" :show-close="false" :close-on-click-modal="false">
        <el-form ref="addSectionForm" :rules="rulesAddSection" size="medium" label-position="left">
          <el-row :gutter="24">
            <el-col :md="24" :lg="24" :xs="24" :sm="24">
              <el-form-item label="Section:" prop="section" style="padding: 0px 10px 0px 10px;">
                <el-input v-model="addSectionForm.section"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelAddSection" size="mini">Cancel</el-button>
          <el-button style="background:#EB2C3E; color:white;" size="mini">Add</el-button>
        </span>
      </el-dialog>

      <el-dialog title="Add BSC Category" :visible.sync="addBSCDialog" width="40%" :show-close="false" :close-on-click-modal="false">
        <el-form :model="addBSCCategoryForm" ref="addBSCCategoryForm" :rules="rulesAddBSCCategory" size="medium" label-position="top">
          <el-row :gutter="24">
            <el-col :md="18" :lg="18" :xs="18" :sm="18">
              <el-form-item label="BSC Category:" prop="selectedBSCCategory" style="padding: 0px 10px 0px 10px;">
                <el-select v-model="addBSCCategoryForm.selectedBSCCategory" placeholder="-" style="width:100%;">
                  <el-option v-for="category in bscAddCategories"
                    :key="category.id"
                    :value="category.id"
                    :label="category.name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :lg="6" :xs="6" :sm="6">
              <el-form-item label="Weight:" prop="weight" style="padding: 0px 10px 0px 10px;">
                <el-input type="number" v-model.number="addBSCCategoryForm.weight">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelAddBSC" size="mini">Cancel</el-button>
          <el-button @click="addBSCCategory" style="background:#EB2C3E; color:white;" size="mini">Add</el-button>
        </span>
      </el-dialog>

      <el-dialog title="Add Perspective" :visible.sync="addPerspectiveDialog" width="40%" :show-close="false" :close-on-click-modal="false">
        <el-form :model="addPerspectiveForm" ref="addPerspectiveForm" :rules="rulesPerspective" size="medium" label-position="top">
          <el-row :gutter="24">
            <el-col :md="18" :lg="18" :xs="18" :sm="18">
              <el-form-item label="Perspective:" prop="selectedPerspective" style="padding: 0px 10px 0px 10px;">
                <el-select v-model="addPerspectiveForm.selectedPerspective" placeholder="-" style="width:100%;">
                  <el-option v-for="perspective in addPerspectives"
                    :key="perspective.id"
                    :value="perspective.id"
                    :label="perspective.name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :lg="6" :xs="6" :sm="6">
              <el-form-item label="Weight:" prop="weight" style="padding: 0px 10px 0px 10px;">
                <el-input type="number" v-model="addPerspectiveForm.weight">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelAddPerspective" size="mini">Cancel</el-button>
          <el-button @click="addPerspectiveConfirm" style="background:#EB2C3E; color:white;" size="mini">Add</el-button>
        </span>
      </el-dialog>

      <el-dialog title="Add Strategic Objective" :visible.sync="addObjectiveDialog" width="40%" :show-close="false" :close-on-click-modal="false">
        <el-form :model="addObjectiveForm" ref="addObjectiveForm" :rules="rulesObjective" size="medium" label-position="top">
          <el-row :gutter="24">
            <el-col :md="18" :lg="18" :xs="18" :sm="18">
              <el-form-item label="Strategic Objective:" prop="selectedObjective" style="padding: 0px 10px 0px 10px;">
                <el-select v-model="addObjectiveForm.selectedObjective" placeholder="-" style="width:100%;">
                  <el-option v-for="objective in addObjectives"
                    :key="objective.id"
                    :value="objective.id"
                    :label="objective.name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :lg="6" :xs="6" :sm="6">
              <el-form-item type="number" label="Weight:" prop="weight" style="padding: 0px 10px 0px 10px;">
                <el-input v-model="addObjectiveForm.weight">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelAddObjective" size="mini">Cancel</el-button>
          <el-button @click="addObjectiveConfirm" style="background:#EB2C3E; color:white;" size="mini">Add</el-button>
        </span>
      </el-dialog>

      <!-- <el-dialog title="Add Strategic Measurement" :visible.sync="addMeasurementDialog" width="40%" :show-close="false" :close-on-click-modal="false">
        <el-form :model="addMeasurementForm" ref="addMeasurementForm" :rules="rulesMeasurement" size="medium" label-position="top">
          <el-row :gutter="24">
            <el-col :md="18" :lg="18" :xs="18" :sm="18">
              <el-form-item label="Strategic Measurement:" prop="selectedMeasurement" style="padding: 0px 10px 0px 10px;">
                <el-select v-model="addMeasurementForm.selectedMeasurement" placeholder="-" style="width:100%;">
                  <el-option v-for="measurement in addMeasurements"
                    :key="measurement.id"
                    :value="measurement.id"
                    :label="measurement.name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :lg="6" :xs="6" :sm="6">
              <el-form-item label="Weight:" prop="weight" style="padding: 0px 10px 0px 10px;">
                <el-input v-model="addMeasurementForm.weight">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelAddMeasurement" size="mini">Cancel</el-button>
          <el-button @click="addMeasurementConfirm" style="background:#EB2C3E; color:white;" size="mini">Add</el-button>
        </span>
      </el-dialog> -->

      <div slot="header" class="card-header">
        <h3 style="margin: 0;">Balance Score Card Maintenance</h3>
      </div>
      <div v-if="selectedDept === null">
        <el-row :gutter="24">
          <el-col :span="5" style="float: right;">
            <el-input
              v-model="deptSearchText"
              size="mini"
              @click="hello(scope.row)"
              placeholder="Search for Department"
            />
          </el-col>
        </el-row>
        <el-table
          :data="bscList.filter(data => !bscSearchText || data.name.toLowerCase().includes(bscSearchText.toLowerCase()))"
          style="width: 100%; margin-bottom: 20px;">
          <el-table-column
            prop="bc_id_name"
            label="Company"
            >
          </el-table-column>
          <el-table-column
            prop="name"
            label="Name"
            >
            <template slot-scope="scope">
              <span @click="getDeptWithinBSC(scope.row.id)" style="cursor:pointer;">{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="a_id_name"
            label="Status"
            >
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="Date Created"
            >
          </el-table-column>
          <el-table-column
            prop="total_department"
            label="Total Department"
            >
          </el-table-column>
          <el-table-column
            prop="pending"
            label="Pending"
            >
          </el-table-column>
          <!-- <el-table-column
            label="Action"
            align="right"
            >
            <template slot-scope="scope">
              <el-button style="background:#EB2C3E; color:white;" @click="hello(scope)" size="mini">Send For Review</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <el-row :gutter="24">
          <el-col :span="5" style="float: right;">
            <el-input
              v-model="bscSearchText"
              size="mini"
              @click="hello(scope.row)"
              placeholder="Search for Evaluation"
            />
          </el-col>
        </el-row>
        <el-table
          :data="tableData.filter(data => !deptSearchText || data.bd_id_name.toLowerCase().includes(deptSearchText.toLowerCase()))"
          style="width: 100%">
          <el-table-column
            prop="bd_id_name"
            label="Department Name"
            >
          </el-table-column>
          <el-table-column
            prop="name"
            label="Head"
            >
          </el-table-column>
          <el-table-column
            prop="a_id_name"
            label="Status"
            >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Action"
            align="right"
            >
            <template slot-scope="scope" style="text-align: center;">
              <el-button style="background:#EB2C3E; color:white;" @click="selectDept(scope.row)" size="mini">Create BSC</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <el-row style="margin-bottom: 10px;"><el-button @click="selectedDept = null" size="mini"><i class="el-icon-back"></i></el-button></el-row>
        <span>Department: <b>{{selectedDept.bd_id_name}}</b></span>
        <el-form :model="addBSCForm" ref="addBSCForm" :rules="rulesBSCForm" size="mini" label-position="top">
          <el-row :gutter="24" style="padding-top: 20px;">
            <el-col :md="5" :lg="5" :xs="24" :sm="24" >
              <el-form-item label="Section:" prop="selectedSection" size="mini">
                <el-select v-model="addBSCForm.selectedSection" placeholder="-" @change="handleSelect($event, 'section')">
                  <el-option-group>
                    <el-option v-for="section in sections"
                    :key="section.id"
                    :value="section.id"
                    :label="section.name">
                    </el-option>
                  </el-option-group>
                  <!-- <el-option-group>
                    <el-option value="addSection" label="Add Section">
                    </el-option>
                  </el-option-group> -->
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :lg="4" :xs="24" :sm="24" >
              <el-form-item label="Rank:" prop="selectedRank" size="mini">
                <el-select v-model="addBSCForm.selectedRank" placeholder="-" :disabled="addBSCForm.selectedSection === ''" @change="handleSelect($event, 'rank')">
                  <el-option v-for="rank in ranks"
                    :key="rank.id"
                    :value="rank.id"
                    :label="rank.name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="5" :lg="5" :xs="24" :sm="24" >
              <el-form-item label="BSC Category:" prop="selectedBSCCategory">
                <el-select style="width:100%;" v-model="addBSCForm.selectedBSCCategory" placeholder="-" :disabled="addBSCForm.selectedRank === ''" @change="handleSelect($event, 'bscCategory')">
                  <el-option-group>
                    <el-option v-for="category in bscCategories"
                      :key="category.id"
                      :value="category.id"
                      :label="category.category_bg4_id_name">
                      <!-- <span style="float: left">{{ category.category_bg4_id_name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 9px !important;">{{ category.weight }}</span> -->
                    </el-option>
                  </el-option-group>
                  <el-option-group>
                    <el-option value="addBSC" label="Add BSC Category">
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="5" :lg="5" :xs="24" :sm="24" >
              <el-form-item label="Perspective:" prop="selectedPerspective">
                <el-select v-model="addBSCForm.selectedPerspective" placeholder="-" :disabled="addBSCForm.selectedBSCCategory === ''" @change="handleSelect($event, 'perspective')">
                  <el-option-group>
                    <el-option v-for="perspective in perspectives"
                      :key="perspective.id"
                      :value="perspective.id"
                      :label="perspective.perspective_bg4_id_name">
                    </el-option>
                  </el-option-group>
                  <el-option-group>
                    <el-option value="addPerspective" label="Add Perspective">
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="5" :lg="5" :xs="24" :sm="24" >
              <el-form-item label="Strategic Objective:" prop="selectedStrategicObjective">
                <el-select v-model="addBSCForm.selectedStrategicObjective" placeholder="-" :disabled="addBSCForm.selectedPerspective === ''" @change="handleSelect($event, 'objective')">
                  <el-option-group>
                    <el-option v-for="objective in objectives"
                      :key="objective.id"
                      :value="objective.id"
                      :label="objective.objective_bg4_id_name">
                    </el-option>
                  </el-option-group>
                  <el-option-group>
                    <el-option value="addObjective" label="Add Strategic Objective">
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" v-if="addBSCForm.selectedStrategicObjective !== ''">
            <el-col :md="4" :lg="4" :xs="24" :sm="24">
              <el-form-item label="Strategic Measurement:" prop="selectedStrategicMeasurement">
                <el-select v-show="!isEdit" v-model="addBSCForm.selectedStrategicMeasurement" placeholder="-" :disabled="addBSCForm.selectedStrategicObjective === ''" @change="handleSelect($event, 'measurement')">
                  <!-- <el-option-group> -->
                    <el-option v-for="measurement in addMeasurements"
                      :key="measurement.id"
                      :value="measurement.id"
                      :label="measurement.name">
                    </el-option>
                  <!-- </el-option-group> -->
                  <!-- <el-option-group>
                    <el-option value="addMeasurement" label="Add Strategic Measurement (KPI)">
                    </el-option>
                  </el-option-group> -->
                </el-select>
                <el-input readonly :value="selectedEditName" v-if="isEdit"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :lg="4" :xs="24" :sm="24">
              <el-form-item label="Threshold:" prop="threshold">
                <el-input v-model="addBSCForm.threshold">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :lg="4" :xs="24" :sm="24">
              <el-form-item label="Target:" prop="target">
                <el-input v-model="addBSCForm.target">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :lg="4" :xs="24" :sm="24">
              <el-form-item label="Superior:" prop="superior">
                <el-input v-model="addBSCForm.superior">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :lg="4" :xs="24" :sm="24">
              <el-form-item label="Actual:" prop="actual">
                <el-input type="number" v-model="addBSCForm.actual">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :lg="4" :xs="24" :sm="24">
              <el-form-item label="Weight:" prop="weight">
                <el-input type="number" v-model="addBSCForm.weight">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="24" :lg="24" :xs="24" :sm="24" v-if="!isEdit">
              <el-button style="float:right;" size="mini" @click="submitScoreCard">Add Score Card</el-button>
            </el-col>
            <el-col :md="24" :lg="24" :xs="24" :sm="24" v-else>
              <el-button style="float:right; margin-left: 5px;" size="mini" @click="submitUpdateScoreCard">Update Score Card</el-button>
              <el-button style="float:right;" size="mini" @click="cancelEdit">Cancel Edit</el-button>
            </el-col>
          </el-row>
        </el-form>
        <el-row>
          <el-table
            :data="measurementTable"
            style="width: 100%">
            <el-table-column
              prop="measurement_bg4_id_name"
              label="Strategic Measurement (KPI)"
              >
            </el-table-column>
            <el-table-column
              prop="threshold"
              label="Threshold"
              >
            </el-table-column>
            <el-table-column
              prop="target"
              label="Target"
              >
            </el-table-column>
            <el-table-column
              prop="superior"
              label="Superior"
              >
            </el-table-column>
            <el-table-column
              prop="actual"
              label="Actual"
              >
            </el-table-column>
            <el-table-column
              prop="weight"
              label="Weight"
              >
            </el-table-column>
            <el-table-column
              fixed="right"
              label="Action"
              align="right"
              >
              <template slot-scope="scope" style="text-align: center;">
                <el-tooltip content="Edit" placement="top" v-if="!scope.row.isEdit">
                  <el-button type="text" @click="editThis(scope.row)" ><i class="el-icon-edit"></i></el-button>
                </el-tooltip>
                <el-tooltip content="Delete" placement="top" v-if="!scope.row.isEdit">
                  <el-button type="text" @click="deleteThis(scope.row.id)" ><i class="el-icon-delete"></i></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>
    </el-card>
    <fab :action="showAddDialog" tooltip="Add BSC"/>
  </div>
</template>
<script>
import fab from '@/components/FAB/FAB.vue'
export default {
  components: {
    fab
  },
  created () {
    this.getCompanies()
    this.getBSCList()
    this.getRanks()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  computed: {
    labelPos () {
      if (this.windowWidth < 992) {
        return 'top'
      } else {
        return 'left'
      }
    }
  },
  data () {
    return {
      deptSearchText: '',
      bscSearchText: '',
      addBSCCompanyForm: {
        selectedCompany: '',
        bscName: '',
        selectedPreviousBSC: ''
      },
      addEvalForm: {
        evalName: '',
        selectedCompany: '',
        evalDate: '',
        // evalYear: null,
        selectedEvalType: '',
        selectedEmploymentStatus: '',
        evalDateCovered: []
        // evalEmploymentCovered: ''
      },
      bscList: [],
      selectedEditName: '',
      selectedEditItem: '',
      isEdit: false,
      addObjectiveDialog: false,
      addPerspectiveDialog: false,
      addMeasurementForm: {
        selectedMeasurement: '',
        weight: ''
      },
      addObjectiveForm: {
        selectedObjective: '',
        weight: ''
      },
      addBSCCategoryForm: {
        selectedBSCCategory: '',
        weight: ''
      },
      addBSCDialog: false,
      addSectionDialog: false,
      selectedDept: null,
      windowWidth: window.innerWidth,
      searchText: '',
      selectedCompany: '',
      addSectionForm: {
        section: ''
      },
      addBSCForm: {
        selectedSection: '',
        selectedRank: '',
        selectedBSCCategory: '',
        selectedPerspective: '',
        selectedStrategicObjective: '',
        selectedStrategicMeasurement: '',
        threshold: '',
        target: '',
        superior: '',
        actual: '',
        weight: ''
      },
      rulesBSCCompany: {
        selectedCompany: [
          { required: true, message: 'Please select a company!', trigger: 'blur' }
        ],
        bscName: [
          { required: true, message: 'Please input a name!', trigger: 'blur' }
        ]
        // selectedPreviousBSC: [
        //   { required: true, message: 'Please select a BSC Category!', trigger: 'blur' }
        // ]
      },
      rulesBSCForm: {
        selectedSection: [
          { required: true, message: 'Please select a section!', trigger: 'blur' }
        ],
        selectedRank: [
          { required: true, message: 'Please select a rank!', trigger: 'blur' }
        ],
        selectedBSCCategory: [
          { required: true, message: 'Please select a BSC Category!', trigger: 'blur' }
        ],
        selectedPerspective: [
          { required: true, message: 'Please select a Perspective!', trigger: 'blur' }
        ],
        selectedStrategicObjective: [
          { required: true, message: 'Please select a Strategic Objective!', trigger: 'blur' }
        ],
        selectedStrategicMeasurement: [
          { required: true, message: 'Please select a Strategic Meausrement!', trigger: 'blur' }
        ],
        threshold: [
          { required: true, message: 'Please select a Threshold!', trigger: 'blur' }
        ],
        target: [
          { required: true, message: 'Please input a Target!', trigger: 'blur' }
        ],
        superior: [
          { required: true, message: 'Please input a Superior!', trigger: 'blur' }
        ],
        actual: [
          { required: true, message: 'Please input an Actual!', trigger: 'blur' }
        ],
        weight: [
          { required: true, message: 'Please input a Weight!', trigger: 'blur' }
        ]
      },
      addPerspectiveForm: {
        selectedPerspective: '',
        weight: ''
      },
      rulesMeasurement: {
        selectedMeasurement: [
          { required: true, message: 'Please select a Strategic Measurement!', trigger: 'blur' }
        ],
        weight: [
          { required: true, message: 'Please input weight!', trigger: 'blur' }
        ]
      },
      rulesAddSection: {
        section: [
          { required: true, message: 'Please input a section!', trigger: 'blur' }
        ]
      },
      rulesObjective: {
        selectedObjective: [
          { required: true, message: 'Please select a Strategic Objective!', trigger: 'blur' }
        ],
        weight: [
          { required: true, message: 'Please input weight!', trigger: 'blur' }
        ]
      },
      rulesPerspective: {
        selectedPerspective: [
          { required: true, message: 'Please select a Perspective!', trigger: 'blur' }
        ],
        weight: [
          { required: true, message: 'Please input weight!', trigger: 'blur' }
        ]
      },
      rulesAddBSCCategory: {
        selectedBSCCategory: [
          { required: true, message: 'Please select a BSC Category!', trigger: 'blur' }
        ],
        weight: [
          { required: true, message: 'Please input weight!', trigger: 'blur' }
        ]
      },
      addMeasurementDialog: false,
      companies: [],
      data: [],
      tableData: [],
      sections: [],
      ranks: [],
      bscCategories: [],
      perspectives: [],
      objectives: [],
      bscAddCategories: [],
      addPerspectives: [],
      addObjectives: [],
      measurements: [],
      addMeasurements: [],
      measurementTable: [],
      addDialog: false
    }
  },
  methods: {
    submitAddBSCCompanyForm () {
      this.$refs.addBSCCompanyForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.bc_id = this.addBSCCompanyForm.selectedCompany
          formSubmit.a_id = 23
          formSubmit.name = this.addBSCCompanyForm.bscName
          // formSubmit.bmemb_id_previous = this.addBSCForm.threshold
          this.$http
            .post('backend.mpp-bsc-maintenance', formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.getBSCList()
                this.addDialog = false
                this.$refs.addBSCCompanyForm.resetFields()
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
            .catch(() => {
              this.$message({
                showClose: true,
                type: 'danger',
                message: 'Uh-oh! Something went wrong.'
              })
            })
        }
      })
    },
    getCompanies () {
      this.$http
        .get('backend.company-list')
        .then(res => {
          // console.log(res)
          this.companies = res.data.body
        })
    },
    cancelAddEval () {
      this.addDialog = false
      this.noDate = 0
      this.$refs.addBSCCompanyForm.resetFields()
    },
    showAddDialog () {
      this.evalDepts = []
      this.addDialog = true
    },
    deleteThis (id) {
      this.$http
        .delete('backend.mpp-maintenance-measurement-delete/' + id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            // this.perspectives = []
          } else {
            this.getAddMeasurements(this.addBSCForm.selectedStrategicObjective)
            this.getTableMeasurement(this.addBSCForm.selectedStrategicObjective)
          }
        })
    },
    cancelEdit () {
      this.isEdit = false
      this.selectedEditItem = ''
      this.addBSCForm.selectedStrategicMeasurement = ''
      this.addBSCForm.threshold = ''
      this.addBSCForm.target = ''
      this.addBSCForm.superior = ''
      this.addBSCForm.actual = ''
      this.addBSCForm.weight = ''
    },
    editThis (item) {
      // console.log(item)
      this.isEdit = true
      this.selectedEditName = item.measurement_bg4_id_name
      this.selectedEditItem = item.id
      this.addBSCForm.selectedStrategicMeasurement = parseInt(item.measurement_bg4_id)
      this.addBSCForm.threshold = item.threshold
      this.addBSCForm.target = item.target
      this.addBSCForm.superior = item.superior
      this.addBSCForm.actual = item.actual
      this.addBSCForm.weight = item.weight
    },
    submitUpdateScoreCard () {
      this.$refs.addBSCForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.id = this.selectedEditItem
          formSubmit.bemdbi3_id = this.addBSCForm.selectedStrategicObjective
          formSubmit.measurement_bg4_id = this.addBSCForm.selectedStrategicMeasurement
          formSubmit.threshold = this.addBSCForm.threshold
          formSubmit.superior = this.addBSCForm.superior
          formSubmit.weight = this.addBSCForm.weight
          formSubmit.actual = this.addBSCForm.actual
          formSubmit.target = this.addBSCForm.target
          formSubmit.points = 0
          formSubmit.rating = 0
          this.$http
            .post('backend.mpp-maintenance-measurement-update ', formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.getTableMeasurement(this.addBSCForm.selectedStrategicObjective)
                this.addBSCForm.selectedStrategicMeasurement = ''
                this.addBSCForm.threshold = ''
                this.addBSCForm.target = ''
                this.addBSCForm.superior = ''
                this.addBSCForm.actual = ''
                this.addBSCForm.weight = ''
                this.isEdit = false
                this.selectedEditItem = ''
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
            .catch(() => {
              this.$message({
                showClose: true,
                type: 'danger',
                message: 'Uh-oh! Something went wrong.'
              })
            })
        }
      })
    },
    submitScoreCard () {
      this.$refs.addBSCForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.bemdbi3_id = this.addBSCForm.selectedStrategicObjective
          formSubmit.measurement_bg4_id = this.addBSCForm.selectedStrategicMeasurement
          formSubmit.threshold = this.addBSCForm.threshold
          formSubmit.superior = this.addBSCForm.superior
          formSubmit.weight = this.addBSCForm.weight
          formSubmit.actual = this.addBSCForm.actual
          formSubmit.target = this.addBSCForm.target
          formSubmit.points = 0
          formSubmit.rating = 0
          this.$http
            .post('backend.mpp-maintenance-measurement', formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.getTableMeasurement(this.addBSCForm.selectedStrategicObjective)
                this.getAddMeasurements(this.addBSCForm.selectedStrategicObjective)
                this.addBSCForm.selectedStrategicMeasurement = ''
                this.addBSCForm.threshold = ''
                this.addBSCForm.target = ''
                this.addBSCForm.superior = ''
                this.addBSCForm.actual = ''
                this.addBSCForm.weight = ''
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
            .catch(() => {
              this.$message({
                showClose: true,
                type: 'danger',
                message: 'Uh-oh! Something went wrong.'
              })
            })
        }
      })
    },
    getTableMeasurement (id) {
      this.$http
        .get('backend.mpp-maintenance-measurement-list-by-objective/' + id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.measurementTable = []
          } else {
            this.measurementTable = res.data.body
          }
        })
    },
    getBSCList () {
      this.$http
        .get('backend.mpp-bsc-maintenance-list')
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.bscList = []
          } else {
            this.bscList = res.data.body
          }
        })
    },
    cancelAddMeasurement () {
      this.addMeasurementDialog = false
    },
    addMeasurementConfirm () {
      this.$refs.addMeasurementForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.bemdbi2_id = this.addBSCForm.selectedPerspective
          formSubmit.objective_bg4_id = this.addObjectiveForm.selectedObjective
          formSubmit.weight = this.addObjectiveForm.weight
          this.$http
            .post('backend.mpp-maintenance-objective', formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.addMeasurementDialog = false
                this.getMeasurements(this.addBSCForm.selectedStrategicObjective)
                this.$refs.addMeasurementForm.resetFields()
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
            .catch(() => {
              this.$message({
                showClose: true,
                type: 'danger',
                message: 'Uh-oh! Something went wrong.'
              })
            })
        }
      })
    },
    addObjectiveConfirm () {
      this.$refs.addObjectiveForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.bemdbi2_id = this.addBSCForm.selectedPerspective
          formSubmit.objective_bg4_id = this.addObjectiveForm.selectedObjective
          formSubmit.weight = this.addObjectiveForm.weight
          this.$http
            .post('backend.mpp-maintenance-objective', formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.addObjectiveDialog = false
                this.getObjectives(this.addBSCForm.selectedPerspective)
                this.getAddObjectives(this.addBSCForm.selectedPerspective)
                this.$refs.addObjectiveForm.resetFields()
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
            .catch(() => {
              this.$message({
                showClose: true,
                type: 'danger',
                message: 'Uh-oh! Something went wrong.'
              })
            })
        }
      })
    },
    cancelAddObjective () {
      this.addObjectiveDialog = false
      this.$refs.addObjectiveForm.resetFields()
    },
    addPerspectiveConfirm () {
      this.$refs.addPerspectiveForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.bemdbi1_id = this.addBSCForm.selectedBSCCategory
          formSubmit.perspective_bg4_id = this.addPerspectiveForm.selectedPerspective
          formSubmit.weight = this.addPerspectiveForm.weight
          this.$http
            .post('backend.mpp-maintenance-perspective', formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.addPerspectiveDialog = false
                this.getPerspectives(this.addBSCForm.selectedBSCCategory)
                this.getAddPerspectives(this.addBSCForm.selectedBSCCategory)
                this.$refs.addPerspectiveForm.resetFields()
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
            .catch(() => {
              this.$message({
                showClose: true,
                type: 'danger',
                message: 'Uh-oh! Something went wrong.'
              })
            })
        }
      })
    },
    addBSCCategory () {
      this.$refs.addBSCCategoryForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.bd_id = this.selectedDept.bd_id
          formSubmit.bds_id = this.addBSCForm.selectedSection
          formSubmit.bj_id = this.addBSCForm.selectedRank
          formSubmit.category_bg4_id = this.addBSCCategoryForm.selectedBSCCategory
          formSubmit.weight = this.addBSCCategoryForm.weight
          formSubmit.bmemd_id = this.selectedDept.id
          this.$http
            .post('backend.mpp-maintenance-category', formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.addBSCDialog = false
                this.getBSCCategories()
                this.getBSCAddCategories()
                this.$refs.addBSCCategoryForm.resetFields()
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
            .catch(() => {
              this.$message({
                showClose: true,
                type: 'danger',
                message: 'Uh-oh! Something went wrong.'
              })
            })
        }
      })
    },
    cancelAddPerspective () {
      this.addPerspectiveDialog = false
      this.$refs.addPerspectiveForm.resetFields()
    },
    cancelAddBSC () {
      this.addBSCDialog = false
      this.$refs.addBSCCategoryForm.resetFields()
    },
    handleSelect (event, type) {
      // console.log(event)
      if (event === 'addBSC') {
        this.cancelEdit()
        this.getBSCAddCategories()
        this.measurementTable = []
        this.addBSCDialog = true
        this.addBSCForm.selectedBSCCategory = ''
        this.addBSCForm.selectedPerspective = ''
        this.addBSCForm.selectedStrategicObjective = ''
        this.perspectives = []
        this.objectives = []
      }
      if (event === 'addSection') {
        this.cancelEdit()
        this.addSectionDialog = true
        this.addBSCForm.selectedSection = ''
      }
      if (type === 'bscCategory' && event !== 'addBSC') {
        this.cancelEdit()
        this.getPerspectives(event)
        this.getAddPerspectives(event)
        this.measurementTable = []
        this.addBSCForm.selectedPerspective = ''
        this.addBSCForm.selectedStrategicObjective = ''
        this.perspectives = []
        this.objectives = []
      }
      if (type === 'section') {
        this.cancelEdit()
        this.measurementTable = []
        this.addBSCForm.selectedRank = ''
        this.addBSCForm.selectedBSCCategory = ''
        this.addBSCForm.selectedPerspective = ''
        this.addBSCForm.selectedStrategicObjective = ''
        this.addBSCForm.selectedStrategicMeasurement = ''
        this.addBSCForm.threshold = ''
        this.addBSCForm.target = ''
        this.addBSCForm.superior = ''
        this.addBSCForm.actual = ''
        this.addBSCForm.weight = ''
        this.bscCategories = []
        this.perspectives = []
        this.objectives = []
      }
      if (type === 'rank') {
        this.cancelEdit()
        this.getBSCCategories()
        this.measurementTable = []
        this.addBSCForm.selectedBSCCategory = ''
        this.addBSCForm.selectedPerspective = ''
        this.addBSCForm.selectedStrategicObjective = ''
        this.addBSCForm.selectedStrategicMeasurement = ''
        this.addBSCForm.threshold = ''
        this.addBSCForm.target = ''
        this.addBSCForm.superior = ''
        this.addBSCForm.actual = ''
        this.addBSCForm.weight = ''
        this.bscCategories = []
        this.perspectives = []
        this.objectives = []
      }
      if (type === 'perspective' && event !== 'addPerspective') {
        this.cancelEdit()
        this.addBSCForm.selectedStrategicObjective = ''
        this.measurementTable = []
        this.addBSCForm.selectedStrategicObjective = ''
        this.addBSCForm.selectedStrategicMeasurement = ''
        this.addBSCForm.threshold = ''
        this.addBSCForm.target = ''
        this.addBSCForm.superior = ''
        this.addBSCForm.actual = ''
        this.addBSCForm.weight = ''
        this.objectives = []
        this.getObjectives(event)
        this.getAddObjectives(event)
      }
      if (type === 'objective' && event !== 'addObjective') {
        this.cancelEdit()
        this.getTableMeasurement(event)
        this.getAddMeasurements(event)
        this.addBSCForm.selectedStrategicMeasurement = ''
        this.addBSCForm.threshold = ''
        this.addBSCForm.target = ''
        this.addBSCForm.superior = ''
        this.addBSCForm.actual = ''
        this.addBSCForm.weight = ''
      }
      if (event === 'addPerspective') {
        this.cancelEdit()
        this.addBSCForm.selectedStrategicObjective = ''
        this.measurementTable = []
        this.addPerspectiveDialog = true
        this.addBSCForm.selectedPerspective = ''
        this.addBSCForm.selectedStrategicObjective = ''
        this.addBSCForm.selectedStrategicMeasurement = ''
        this.addBSCForm.threshold = ''
        this.addBSCForm.target = ''
        this.addBSCForm.superior = ''
        this.addBSCForm.actual = ''
        this.addBSCForm.weight = ''
      }
      if (event === 'addObjective') {
        this.cancelEdit()
        this.addObjectiveDialog = true
        this.addBSCForm.selectedStrategicObjective = ''
        this.measurementTable = []
        this.addBSCForm.selectedStrategicMeasurement = ''
        this.addBSCForm.threshold = ''
        this.addBSCForm.target = ''
        this.addBSCForm.superior = ''
        this.addBSCForm.actual = ''
        this.addBSCForm.weight = ''
      }
      if (event === 'addMeasurement') {
        this.cancelEdit()
        this.addMeasurementDialog = true
        this.addBSCForm.selectedStrategicMeasurement = ''
      }
    },
    getObjectives (id) {
      this.$http
        .get('backend.mpp-maintenance-objective-list-by-perspective/' + id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.objectives = []
          } else {
            this.objectives = res.data.body
          }
        })
    },
    getMeasurements (id) {
      this.$http
        .get('backend.mpp-maintenance-measurement-list-by-global4/' + id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.measurements = []
          } else {
            this.measurements = res.data.body
          }
        })
    },
    getPerspectives (id) {
      this.$http
        .get('backend.mpp-maintenance-perspective-list-by-category/' + id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.perspectives = []
          } else {
            this.perspectives = res.data.body
          }
        })
    },
    getAddMeasurements (id) {
      this.$http
        .get('backend.mpp-maintenance-measurement-list-by-global4/' + id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.addMeasurements = []
          } else {
            this.addMeasurements = res.data.body
          }
        })
    },
    getAddObjectives (id) {
      this.$http
        .get('backend.mpp-maintenance-objective-data-list/' + id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.addObjectives = []
          } else {
            this.addObjectives = res.data.body
          }
        })
    },
    getAddPerspectives () {
      this.$http
        .get('backend.mpp-maintenance-perspective-data-list/' + this.addBSCForm.selectedBSCCategory)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.addPerspectives = []
          } else {
            this.addPerspectives = res.data.body
          }
        })
    },
    getBSCAddCategories () {
      this.$http
        .get('backend.mpp-maintenance-category-data-list/' + this.selectedDept.bd_id + '/' + this.addBSCForm.selectedSection + '/' + this.addBSCForm.selectedRank + '/' + this.selectedDept.id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.bscAddCategories = []
          } else {
            this.bscAddCategories = res.data.body
          }
        })
    },
    getBSCCategories () {
      this.$http
        .get('backend.mpp-maintenance-list-by-category/' + this.selectedDept.bd_id + '/' + this.addBSCForm.selectedSection + '/' + this.addBSCForm.selectedRank + '/' + this.selectedDept.id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.bscCategories = []
          } else {
            this.bscCategories = res.data.body
          }
        })
    },
    getRanks () {
      this.$http
        .get('backend.global4-list/31')
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.ranks = []
          } else {
            this.ranks = res.data.body
          }
        })
    },
    getSections () {
      // console.log(this.selectedDept)
      this.$http
        .get('backend.department.section-list/' + this.selectedDept.bd_id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.sections = []
          } else {
            this.sections = res.data.body
          }
        })
    },
    selectDept (item) {
      this.selectedDept = item
      this.getSections()
      this.postBDID(item)
    },
    getDeptWithinBSC (id) {
      this.$http
        .get('backend.mpp-maintenance-dept-list-by-bsc/' + id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.tableData = []
          } else {
            this.tableData = res.data.body
          }
        })
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    hello (hello) {
      // console.log(hello)
    },
    cancelAddSection () {
      this.addSectionDialog = false
    },
    postBDID (item) {
      // console.log(item)
      var params = {
        bd_id: item.bd_id,
        a_id: 1,
        bmemd_id: item.id
      }
      this.$http
        .post('backend.mpp-maintenance', params)
    }
  }
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
